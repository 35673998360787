var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticClass:"container px-0"},[_c('v-row',{staticClass:"title-row",class:`${
        _vm.$vuetify.breakpoint.mdAndDown ? 'flex-column flex-nowrap' : ''
      }`},[_c('v-col',{staticClass:"d-flex align-center title-column"},[_c('h4',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm.$t('heading.hosting.summary.title')))]),(
            !_vm.loading &&
            _vm.service.privileges.includes('hosting.control_panel_sso')
          )?_c('v-btn',{staticClass:"p-2 font-weight-bold ml-auto",attrs:{"large":"","color":"primary","elevation":"0","href":'#',"target":'_blank',"loading":_vm.controlpanelSsoLoading},on:{"click":function($event){$event.preventDefault();return _vm.controlPanelSsoLogin(_vm.selectedServerAccount.id)}}},[_c('v-icon',{staticClass:"white--text",attrs:{"size":"22"}},[_vm._v("$externallink")]),_vm._v(" "+_vm._s(_vm.$t("button.login.controlPanel"))+" ")],1):_vm._e()],1),(_vm.service && _vm.service.server_accounts.length > 1)?_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"6"}},[_c('active-server-account-selector',{attrs:{"value":_vm.selectedServerAccount,"items":_vm.service.server_accounts},on:{"update:value":function($event){_vm.selectedServerAccount=$event}}})],1):_vm._e()],1)],1),_c('v-container',{staticClass:"mt-3 px-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('hosting-nameservers-card',{attrs:{"service":_vm.service,"selectedServerAccount":_vm.selectedServerAccount,"server-accounts":_vm.service.server_accounts,"loading":_vm.loading,"instances":_vm.instances}}),_c('hosting-instances-card',{attrs:{"service":_vm.service,"loading":_vm.loading},on:{"instances-loaded":function($event){_vm.instances = $event}}})],1),(!_vm.selectedServerAccount.diagnostic_mode)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.loading)?_c('hosting-summary-card-skeleton'):_c('hosting-summary-card',{attrs:{"usageData":_vm.planUsage,"serverAccount":_vm.service,"service":_vm.service,"availableSpace":_vm.planUsageHeader},scopedSlots:_vm._u([{key:"action",fn:function(){return [(_vm.service.plan_upgrade_link)?_c('v-btn',{attrs:{"href":_vm.service.plan_upgrade_link +
                '?paupgradeserviceid=' +
                _vm.service.id,"target":"_blank","x-small":"","text":"","color":"info"}},[_c('span',{staticClass:"font-weight-600"},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"16"}},[_vm._v("$restorebackup")]),_vm._v(" "+_vm._s(_vm.$t('button.upgrade'))+" ")],1)]):_vm._e()]},proxy:true}],null,false,3616322221)})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }