<template>
  <div>
    <v-container class="container px-0">
      <v-row
        class="title-row"
        :class="`${
          $vuetify.breakpoint.mdAndDown ? 'flex-column flex-nowrap' : ''
        }`"
      >
        <v-col class="d-flex align-center title-column">
          <h4 class="font-weight-light">{{ $t('heading.hosting.summary.title') }}</h4>
          <v-btn
            v-if="
              !loading &&
              service.privileges.includes('hosting.control_panel_sso')
            "
            large
            color="primary"
            class="p-2 font-weight-bold ml-auto"
            elevation="0"
            :href="'#'"
            :target="'_blank'"
            :loading="controlpanelSsoLoading"
            @click.prevent="controlPanelSsoLogin(selectedServerAccount.id)"
          >
            <v-icon size="22" class="white--text">$externallink</v-icon>
            {{ $t("button.login.controlPanel") }}
          </v-btn>
        </v-col>
        <v-col
          v-if="service && service.server_accounts.length > 1"
          cols="12" md="6"
          class="d-flex"
        >
          <active-server-account-selector
            :value.sync="selectedServerAccount"
            :items="service.server_accounts"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-3 px-0">
      <v-row>
        <v-col cols="12" md="6">
          <hosting-nameservers-card
            :service="service"
            :selectedServerAccount="selectedServerAccount"
            :server-accounts="service.server_accounts"
            :loading="loading"
            :instances="instances"
          />
          <hosting-instances-card
            :service="service"
            :loading="loading"
            @instances-loaded="instances = $event"
          />
        </v-col>

        <v-col v-if="!selectedServerAccount.diagnostic_mode" cols="12" md="6">
          <hosting-summary-card-skeleton v-if="loading"/>
          <hosting-summary-card
            v-else
            :usageData="planUsage"
            :serverAccount="service"
            :service="service"
            :availableSpace="planUsageHeader"
          >
            <template v-slot:action>
              <v-btn
                v-if="service.plan_upgrade_link"
                :href="
                  service.plan_upgrade_link +
                  '?paupgradeserviceid=' +
                  service.id
                "
                target="_blank"
                x-small
                text
                color="info"
              >
                <span class="font-weight-600">
                  <v-icon size="16" class="mr-1">$restorebackup</v-icon>
                  {{ $t('button.upgrade') }}
                </span>
              </v-btn>
            </template>
          </hosting-summary-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HostingNameserversCard from "../../components/cards/HostingNameserversCard.vue";
import HostingInstancesCard from "../../components/cards/HostingInstancesCard.vue";
import HostingSummaryCard from "../../components/cards/HostingSummaryCard.vue";
import HostingSummaryCardSkeleton from "../../components/cards/HostingSummaryCardSkeleton.vue";
import ControlPanelSsoMixin from "../../mixins/ControlPanelSsoMixin";
import Api from "../../apis/Api";
import Helper from "../../apis/Helper";
import ActiveServerAccountSelector from "@/components/ActiveServerAccountSelector.vue";

export default {
  components: {
    ActiveServerAccountSelector,
    HostingSummaryCard,
    HostingSummaryCardSkeleton,
    HostingNameserversCard,
    HostingInstancesCard,
  },
  mixins: [ControlPanelSsoMixin],
  props: {
    service: Object,
  },
  watch: {
    service: function () {
      this.selectedServerAccount = this.service.server_accounts[0];
    },
    selectedServerAccount: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.reloadData();
      }
    }
  },
  created() {
    if (this.service) {
      this.selectedServerAccount = this.service.server_accounts[0];
      this.reloadData();
    }
  },
  data: function () {
    return {
      loading: true,
      usage: {},
      usageSupported: {},
      selectedServerAccount: null,
      instances: [],
    };
  },
  computed: {
    planUsageHeader() {
      const header = [
        {
          title: 'disk',
          icon: 'diskspaceIcon',
          available: this.usage.disk_usage.maximum,
          availableText: this.formatBytes(this.usage.disk_usage.maximum),
          value: this.usage.disk_usage.usage,
          valueText: this.formatBytes(this.usage.disk_usage.usage),
        },
      ]

      if (this.usageSupported.email_accounts) {
        header.push({
          title: 'email',
          icon: 'emailaccountsIcon',
          available: this.usage.email_accounts.maximum,
          availableText: this.formatAmount(this.usage.email_accounts.maximum),
          value: this.usage.email_accounts.usage,
          valueText: this.formatAmount(this.usage.email_accounts.usage),
        })
      }
      return header;
    },
    planUsage() {
      if (!Object.keys(this.usage).length) {
        return [];
      }
      const usage = [];

      if (this.usageSupported.bandwidth) {
        usage.push({
          name: 'bandwidth',
          icon: "bandwidthIcon",
          value: parseInt(this.usage.bandwidth.usage),
          valueText: this.formatBytes(this.usage.bandwidth.usage),
          maxValue: parseInt(this.usage.bandwidth.maximum),
          maxValueText: this.formatBytes(this.usage.bandwidth.maximum),
        })
      }

      if (this.usageSupported.ftp_accounts) {
        usage.push({
          name: 'ftp',
          icon: "ftpaccountsIcon",
          value: parseInt(this.usage.ftp_accounts.usage),
          valueText: this.usage.ftp_accounts.usage,
          maxValue: parseInt(this.usage.ftp_accounts.maximum),
          maxValueText: this.formatAmount(this.usage.ftp_accounts.maximum),
          url: "/hosting/ftp",
          privilege: 'hosting.manage_ftp'
        })
      }

      if (this.usageSupported.sftp_accounts) {
        usage.push({
          name: 'sftp',
          icon: "ftpaccountsIcon",
          value: parseInt(this.usage.sftp_accounts.usage),
          valueText: this.usage.sftp_accounts.usage,
          maxValue: parseInt(this.usage.sftp_accounts.maximum),
          maxValueText: this.formatAmount(this.usage.sftp_accounts.maximum),
          url: "/hosting/sftp",
          privilege: 'hosting.manage_sftp'
        })
      }

      if (this.usageSupported.mysql_databases) {
        usage.push({
          name: 'mysql',
          icon: "mysqldatabasesIcon",
          value: parseInt(this.usage.mysql_databases.usage),
          valueText: this.usage.mysql_databases.usage,
          maxValue: parseInt(this.usage.mysql_databases.maximum),
          maxValueText: this.formatAmount(this.usage.mysql_databases.maximum),
          url: "/hosting/databases",
          privilege: 'hosting.manage_mysql'
        })
      }

      if (this.usageSupported.addon_domains) {
        usage.push({
            name: 'addonDomains',
            icon: "addondomainsIcon",
            value: parseInt(this.usage.addon_domains.usage),
            valueText: this.usage.addon_domains.usage,
            maxValue: parseInt(this.usage.addon_domains.maximum),
            maxValueText: this.formatAmount(this.usage.addon_domains.maximum),
            url: "/hosting/domains",
            privilege: 'hosting.manage_domains'
        })
      }

      if (this.usageSupported.subdomains) {
        usage.push({
          name: 'subdomains',
          icon: "subdomainsIcon",
          value: parseInt(this.usage.subdomains.usage),
          valueText: this.usage.subdomains.usage,
          maxValue: parseInt(this.usage.subdomains.maximum),
          maxValueText: this.formatAmount(this.usage.subdomains.maximum),
          url: "/hosting/domains",
          privilege: 'hosting.manage_domains'
        })
      }

      if (this.usageSupported.aliases) {
        usage.push({
          name: 'aliases',
          icon: "subdomainsIcon",
          value: parseInt(this.usage.aliases.usage),
          valueText: this.usage.aliases.usage,
          maxValue: parseInt(this.usage.aliases.maximum),
          maxValueText: this.formatAmount(this.usage.aliases.maximum),
          url: "/hosting/domains",
          privilege: 'hosting.manage_domains'
        })
      }

      if (this.usageSupported.email_accounts) {
        usage.push({
          name: 'email',
          icon: "emailaccountsIcon",
          value: parseInt(this.usage.email_accounts.usage),
          valueText: this.usage.email_accounts.usage,
          maxValue: parseInt(this.usage.email_accounts.maximum),
          maxValueText: this.formatAmount(this.usage.email_accounts.maximum),
          url: "/hosting/email-addresses",
          privilege: 'hosting.manage_email'
        })
      }

      return usage;
    },
  },
  methods: {
    reloadData() {
      if (this.selectedServerAccount.diagnostic_mode) {
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: this.$t("message.hostingAccountInDiagnosticMode"),
        });
        return;
      }
      this.loading = true;
      Api.cached(0)
        .get(`/server-accounts/${this.selectedServerAccount.id}/usage`)
        .then((response) => {
          this.usage = response.data.data;
          this.usageSupported = response.data.supported_metrics;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatBytes(bytes) {
      if (bytes === null) {
        return "∞";
      }
      return Helper.formatBytes(bytes);
    },
    formatAmount(amount) {
      if (amount === null) {
        return "∞";
      }
      return amount;
    },
  },
};
</script>

<style scoped lang="scss">
.title-row {
  @media (min-width: 1401px) {
    height: 70px;
  }
}
.v-card {
  .v-card__text {
    -webkit-line-clamp: unset;
  }
}
</style>
