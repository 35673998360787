<template>
  <v-card elevation="4" class="mb-8">
    <v-card-title class="pb-6 d-flex flex-row justify-space-between">
      <h6 class="font-weight-bold">
        <i18next
          :translation="
            $t('heading.hosting.summary.nameserverCard.mainDomain.headline')
          "
        >
          <template #domain>
            <span class="font-weight-light ml-1">
              {{ splitName()[1] }}
            </span>
          </template>
        </i18next>
        <v-progress-circular
          v-if="resolvingNs"
          :size="20"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </h6>
    </v-card-title>
    <v-card-text v-if="!service"></v-card-text>
    <v-card-text v-else-if="!verificationPassed">
      <div class="d-flex ">
        <v-icon color="error" size="20"> $alertcircle</v-icon>
        <p class="error--text px-2">
          {{ $t("heading.hosting.summary.nameserverCard.dnsRecords.text") }}:
        </p>
      </div>
      <DnsOptions
        :domain="service.domain"
        :onboardingSubdomain="onboardingSubdomain"
        :dnsSettings="service.dns_settings"
        :hasDnsServer="service.dns_server_enabled"
        :ipv4Addresses="selectedServerAccount.ipv4_addresses"
        :nameservers="selectedServerAccount.nameservers"
        hideTitle
      />
    </v-card-text>
  </v-card>
</template>

<script>
import DnsOptions from "../DnsOptions.vue";
import Api from "@/apis/Api";

export default {
  components: {
    DnsOptions,
  },
  data: function () {
    return {
      tooltipOpen: 99,
      resolvingNs: true,
      verificationPassed: true,
    };
  },
  props: {
    service: Object,
    selectedServerAccount: [Object, null],
    serverAccounts: Array,
    loading: Boolean,
    instances: Array,
  },
  computed: {
    onboardingSubdomain() {
      for (let instance of this.instances) {
        if (instance.domain == this.service.domain) {
          return instance.onboarding_subdomain;
        }
      }
      return null;
    },
  },
  created() {
    if (this.service) {
      this.resolveDns();
    }
  },
  watch: {
    service: function () {
      this.resolveDns();
    },
  },
  methods: {
    async resolveDns() {
      this.resolvingNs = true;
      let result = await Api.dns().verifyDomainResolving(
        this.service.server_accounts[0].domain,
        this.onboardingSubdomain,
        this.service.dns_settings,
        this.service.dns_server_enabled,
        this.service.server_accounts[0].ipv4_addresses,
        this.service.server_accounts[0].nameservers
      );
      this.verificationPassed = result;
      this.resolvingNs = false;
    },
    clearTooltips($event) {
      this.tooltipOpen = 99;
      $event.target.blur();
    },
    splitName: function () {
      if (this.service && this.service.name) {
        const split = this.service.name.split(" - ");
        return [split[0], split[1]];
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  .v-card__text {
    -webkit-line-clamp: unset;
  }
}

ul {
  li {
    padding-left: 16px;
    position: relative;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      position: absolute;
      top: calc(50% - 3px);
      left: 0px;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: var(--v-info-base);
    }
  }
}

.v-menu__content {
  overflow-y: auto !important;
  max-height: 360px;

  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: transparent;
    max-width: 8px;
    border: 3px solid transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--v-gray-lighten2);
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }
}


</style>