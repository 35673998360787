import { render, staticRenderFns } from "./HostingNameserversCard.vue?vue&type=template&id=718cb782&scoped=true"
import script from "./HostingNameserversCard.vue?vue&type=script&lang=js"
export * from "./HostingNameserversCard.vue?vue&type=script&lang=js"
import style0 from "./HostingNameserversCard.vue?vue&type=style&index=0&id=718cb782&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "718cb782",
  null
  
)

export default component.exports