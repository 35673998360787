<template>
  <v-card elevation="4" class="mb-8">
    <v-card-title class="pb-6">
      <h6>
        {{ $t('heading.hosting.summary.instancesCard.title') }}
        <span class="font-weight-regular">{{
          !loading ? `(${totalInstances})` : ""
        }}</span>
      </h6>
    </v-card-title>

    <v-divider />

    <v-card-text class="d-flex flex-column pt-2 px-0">
      <template v-if="!loading">
        <template v-if="instances.length">
          <router-link
            :to="`/instances/${item.id}`"
            class="d-flex py-2 instance px-6"
            v-for="(item, index) in instances"
            :key="item.id + '-' + index"
          >
            <router-link :to="`/instances/${item.id}`">
              <instance-avatar
                :instance="item"
                :size="40"
                :ssl="true"
              />
            </router-link>

            <div class="d-flex flex-column ml-4 instance__title">
              <h6 class="d-flex align-center">
                {{ item.title }}
                <v-tooltip
                  v-if="item.service_status === 'suspended'"
                  transition="custom-tooltip"
                  open-delay="150"
                  bottom
                  z-index="99"
                  offset-overflow
                  nudge-bottom="12px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="suspend-chip ml-2" v-on="on" v-bind="attrs">
                      <v-icon color="sand darken-2" size="12" class="mr-1">
                        $suspended
                      </v-icon>
                      {{ $t('message.status.suspended') }}
                    </div>
                  </template>
                  <div class="d-flex align-center">
                    <v-icon color="sand darken-2" size="16">$suspended</v-icon>
                    <span class="sand--text text--darken-2 ml-1">
                      {{ $t('tooltip.hosting.service.suspended') }}
                    </span>
                  </div>
                </v-tooltip>
              </h6>

              <a
                class="instance__link"
                @click="$event.stopPropagation()"
                :href="item.url"
                >{{ item.url }}</a
              >
            </div>
            <div class="d-flex ml-auto">
              <shares-label :instance="item" class="mr-2" />
              <status-indicator :sameSize="true" :instance="item" />
            </div>
          </router-link>
        </template>
        <div class="no-data" v-else>
          <hostingInstancesCardIllustration/>

          <h5 class="my-4">{{ $t('message.emptyTable.instances.title') }}</h5>
          <p class="mb-0">
            {{ $t('message.emptyTable.instances.subtitle') }}
          </p>
          <v-btn
            color="primary"
            elevation="0"
            class="mt-7"
            @click="loadServices"
          >
            <v-icon size="16" class="mr-2">$plus</v-icon>
            {{ $t('button.instance.addNew') }}
          </v-btn>
        </div>
      </template>
      <template v-else>
        <div v-for="item in 5" :key="item" class="d-flex py-2 px-6">
          <v-skeleton-loader type="avatar" class="my-1" />

          <div class="d-flex flex-column ml-4">
            <v-skeleton-loader
              type="text"
              height="16"
              width="140"
              class="mb-1"
            />
            <v-skeleton-loader type="text" height="14" width="80" />
          </div>
        </div>
      </template>
    </v-card-text>

    <v-divider />

    <div
      class=""
      v-if="
        instances.length &&
        instances.length !== totalInstances &&
        totalInstances > 5
      "
    >
      <v-btn
        :loading="loadingServices"
        x-large
        block
        text
        class="load-more-btn"
        @click="reloadInstances(99)"
      >
        <span class="p-4 font-weight-regular">{{ $t('button.loadMore') }}</span>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import Api from "@/apis/Api";

import InstanceAvatar from "../InstanceAvatar.vue";
import StatusIndicator from "../instances/StatusIndicator.vue";
import SharesLabel from "../labels/SharesLabel.vue";
import hostingInstancesCardIllustration from "../illustrations/hosting-instances-card-illustration.vue";

export default {
  components: {
    InstanceAvatar,
    StatusIndicator,
    SharesLabel,
    hostingInstancesCardIllustration,
  },
  props: {
    service: Object
  },
  data: function () {
    return {
      loading: true,
      instances: [],
      totalInstances: 0,
      loadingServices: false,
    };
  },
  watch: {
    service: function () {
      this.$nextTick(() => {
        this.reloadInstances();
      });
    },
  },
  methods: {
    loadServices() {
      this.loadingServices = true;
      Api.services().then(() => {
        this.loadingServices = false;
        this.startInstallationProcess();
      });
    },
    startInstallationProcess: function () {
      this.$store.dispatch("startInstallationProcess");
    },
    reloadInstances: async function (number = 5) {
      this.loading = true;

      if (!this.service) {
        this.loading = false;
        return;
      }

      const results = await Promise.all(this.service.server_accounts.map(async (serverAccount) => {
        let { data, meta, error } = await Api.instances().list(
          1,
          number,
          "created_at",
          true,
          "",
          [],
          serverAccount.id
        );

        if (error || !data) {
          return { error, data: null };
        }

        return { data, meta };
      }));


      this.instances = [];
      this.totalInstances = 0;

      for (const result of results) {
        if (result.error) {
          continue;
        }
        this.instances.push(...result.data);
        this.totalInstances += result.meta.total;
      }
      this.$emit('instances-loaded', this.instances);
      this.loading = false;
    },
    loadAllInstances: async function () {},
  },
  mounted: async function () {
    await this.reloadInstances();
  },
};
</script>

<style lang="scss" scoped>
a {
  color: var(--v-gray-darken1);
}

.load-more-btn::v-deep {
  background: transparent;
  &:before {
    background: transparent !important;
  }
  .v-btn__content {
    > span {
      transition: color 0.24s ease;
    }
  }
  &:hover {
    .v-btn__content {
      > span {
        color: var(--v-primary-base) !important;
      }
    }
  }
}

.v-btn {
  &.v-size--x-large {
    height: 50px;
    font-size: $font-size-xs;
    line-height: $line-height-xs;
    font-weight: $font-weight-normal;
  }
}

.instance {
  transition: background-color 0.3s ease;
  max-width: 100%;
  &__title {
    max-width: calc(100% - 80px);
  }
  h6 {
    max-width: 100%;
    text-overflow: ellipsis;
  }
  &:hover {
    background-color: var(--v-primary-lighten4);
  }
  &__title,
  &__link {
    transition: color 0.3s ease;
  }
  &__title {
    &:hover {
      color: var(--v-primary-darken1);
    }
  }
  &__link {
    &:hover {
      color: var(--v-info-base);
    }
  }
}
.v-skeleton-loader::v-deep {
  .v-skeleton-loader__avatar {
    width: 40px;
    height: 40px;
  }
}

.no-data {
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  h5 {
    color: var(--v-gray-darken2);
    font-size: $font-size-lg;
    line-height: $line-height-lg;
    font-weight: $font-weight-bold;
  }
  p {
    max-width: 280px;
    color: var(--v-gray-darken1);
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    text-align: center;
    font-weight: $font-weight-normal;
  }
}

.suspend-chip {
  border-radius: 5px;
  background-color: var(--v-sand-lighten5);
  color: var(--v-sand-darken2);
  font-weight: $font-weight-bold;
  font-size: $font-size-xxs;
  line-height: $line-height-xxs;
  padding: 4px;
}
</style>
